import React, { useState } from 'react';
import { createAddress } from '~/helpers/requests/customer-requests';
import { Formik } from 'formik';
import AddressFormInputs from './AddressFormInputs';
import { RedButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import Loader from 'react-loader-spinner';

const CreateAddressForm = ({ cookie, address, setRefetch, setIsModalOpen }) => {
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        provinceCode: '',
        zip: '',
        countryCodeV2: 'US',
    });

    return (
        <div
            className="create-address-form"
            style={{
                paddingTop: 44,
            }}
        >
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }
                    if (!values.address1) {
                        errors.address1 = 'Required';
                    }

                    if (!values.city) {
                        errors.city = 'Required';
                    }

                    if (!values.provinceCode) {
                        errors.provinceCode = 'Required';
                    }

                    if (!values.zip) {
                        errors.zip = 'Required';
                    }
                    if (!values.countryCodeV2) {
                        errors.countryCodeV2 = 'Required';
                    }
                    return errors;
                }}
                onSubmit={values => {
                    const mailingAddressInput = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        province: values.provinceCode,
                        zip: values.zip,
                        country: values.countryCodeV2,
                    };
                    createAddress(cookie, mailingAddressInput)
                        .then(res => {
                            setIsModalOpen(false);
                            setRefetch();
                            setInitialValues({
                                firstName: '',
                                lastName: '',
                                address1: '',
                                address2: '',
                                city: '',
                                provinceCode: '',
                                zip: '',
                                countryCodeV2: 'US',
                            });
                        })
                        .catch(err => console.log(err));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <form className="address-form" onSubmit={handleSubmit}>
                        <FlexBox justify="space-between" direction="column" height="100%">
                            <AddressFormInputs
                                address={address}
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            <FlexBox className="submit-btns">
                                <RedButton
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    h="50px"
                                    pd="0 35px"
                                >
                                    {isSubmitting ? (
                                        <>
                                            <Loader
                                                type="TailSpin"
                                                color="#152622"
                                                height={16}
                                                width={16}
                                            />
                                            Loading...
                                        </>
                                    ) : (
                                        'Save'
                                    )}
                                </RedButton>
                            </FlexBox>
                        </FlexBox>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CreateAddressForm;
