import React from 'react';
import Cookies from 'js-cookie';
import { WhiteButton } from 'components/.base/buttons';
import { FlexBox } from 'components/.base/containers';
import { BodyText, BodyTextAlt, Subheading } from 'components/.base/headings';
import EditIcon from '~/images/icons/accountpage/editpen.svg';
import TrashIcon from '~/images/icons/accountpage/trash.svg';
import { deleteAddress, setDefaultAddress } from 'helpers/requests/customer-requests';

const Address = ({
  address,
  i,
  customer,
  setEditingAddress,
  setRefetch,
  setIsAddressDisclaimerModalOpen,
  setHandleAgreeAddressDisclaimer,
}) => {
  const updateDefaultAddress = async () => {
    const res = await setDefaultAddress(Cookies.get('KEPT_SESS'), address.node.id);
    setRefetch(r => !r);
  };

  const deleteAddressFn = async addressID => {
    await deleteAddress(Cookies.get('KEPT_SESS'), addressID);
    setRefetch(r => !r);
  };

  const handleMakeDefaultAddress = () => {
    setIsAddressDisclaimerModalOpen(true);
    setHandleAgreeAddressDisclaimer(() => updateDefaultAddress);
  };
  return address?.node ? (
    <>
      <Subheading>Address {i + 1}</Subheading>
      <BodyText className="address-text">
        {`${address.node.firstName} ${address.node.lastName}`}
        <br />
        {address.node.address1}
        <br />
        {address.node.address2 ? (
          <>
            {address.node.address2}
            <br />
          </>
        ) : null}
        {`${address.node.city}, ${
          address.node.provinceCode ? `${address.node.provinceCode}, ` : null
        }${address.node.countryCodeV2}`}
      </BodyText>
      <FlexBox className="options">
        <FlexBox align="center" onClick={() => setEditingAddress(i)}>
          <BodyTextAlt md="0 .5rem 0 0">Edit</BodyTextAlt>
          <WhiteButton className="no-stroke" w="40px" h="40px" pd="0">
            <EditIcon />
          </WhiteButton>
        </FlexBox>
        <FlexBox align="center" onClick={() => deleteAddressFn(address.node.id)}>
          <BodyTextAlt md="0 .5rem 0 1rem">Delete</BodyTextAlt>
          <WhiteButton className="trash-btn">
            <TrashIcon className="trash-icon" />
          </WhiteButton>
        </FlexBox>
      </FlexBox>
      {customer.defaultAddress.address1 === address.node.address1 &&
      customer.defaultAddress.firstName === address.node.firstName &&
      customer.defaultAddress.lastName === address.node.lastName ? null : (
        <FlexBox justify="flex-start" align="center">
          <WhiteButton
            className="trash-btn"
            w="80%"
            h="40px"
            md="1rem 0 1rem 0"
            onClick={handleMakeDefaultAddress}
          >
            Make Default
          </WhiteButton>
        </FlexBox>
      )}
    </>
  ) : null;
};

export default Address;
