import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { css } from 'styled-components';

import { useBackground } from 'hooks/use-background';
import { getImage } from 'helpers';
import { getShopifyCustomer } from 'helpers/requests/customer-requests';
import { Criteo } from 'components/Criteo/Criteo';
import SEO from 'components/seo';
import { DeriveHeaderClasses } from 'components/Navigation';
import CreateAddressForm from 'components/AccountPages/AddressForm/CreateAddressForm';
import { Heading, BodyText, BodyTextAlt } from 'components/.base/headings';
import { FlexBox, Grid, BackgroundDiv, PageContainerEcomm } from 'components/.base/containers';
import { WhiteButton } from 'components/.base/buttons';
import Modal from 'components/.base/modal.js';
import LogOutIcon from 'images/icons/accountpage/logout.svg';
import BackIcon from 'images/icons/accountpage/back.svg';
import '../account.scss';
import './addresses.scss';
import { AddressDisclaimerModal } from 'components/AccountPages';
import EditAddress from './EditAddress';
import Address from './Address';

const AddressesPage = ({ location }) => {
  const backgroundImages = useBackground().edges;
  const [customer, setCustomer] = useState({});
  const [editingAddress, setEditingAddress] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressDisclaimerModalOpen, setIsAddressDisclaimerModalOpen] = useState(true);
  const [handleAgreeAddressDisclaimer, setHandleAgreeAddressDisclaimer] = useState(() => {});

  useEffect(() => {
    const accessToken = Cookies.get('KEPT_SESS');
    if (!accessToken) window.location.href = '/account/login';
    getShopifyCustomer(accessToken).then(res => {
      setCustomer(res.data.data.customer);
    });
  }, [refetch]);
  const logOut = () => {
    Cookies.remove('KEPT_SESS');
    window.location.href = '/';
  };

  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const initialize = () => {
    console.log('check');
    if (window && window.google) {
      console.log('google');
      setGoogleApiLoaded(true);
    } else {
      console.log('no google');
      setTimeout(initialize, 500);
    }
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBhG2W1tn5AUfDsx3u1OLm24_4flLCUtqA&libraries=places`;
    script.addEventListener('load', initialize);
    document.body.appendChild(script);
  }, []);

  const handleCloseAddressDisclaimerModal = () => {
    setIsAddressDisclaimerModalOpen(false);
  };

  return (
    <PageContainerEcomm className="account account-addresses">
      <SEO title="Account" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <BackgroundDiv
        className="bg-div"
        left="50px"
        top="150px"
        z="-1"
        css={css`
          width: 624px;
          max-width: 100%;
        `}
      >
        <Image fluid={getImage(backgroundImages, 'blurred_smudge_2')} alt="" />
      </BackgroundDiv>
      <header className="header">
        <FlexBox justify="center" className="header-items">
          <div className="back-btn">
            <WhiteButton
              as={Link}
              to="/account/dashboard"
              className="no-stroke"
              w="40px"
              h="40px"
              pd="0"
            >
              <BackIcon style={{ transform: 'rotate(90deg)' }} />
            </WhiteButton>
            <BodyTextAlt md="0 0 0 .75rem">BACK TO my ACCOUNT</BodyTextAlt>
          </div>
          <Heading md="0" align="center">
            <span className="red">My addresses</span>
          </Heading>
          <FlexBox justify="center" align="center" onClick={logOut} className="log-out">
            <BodyTextAlt md="0 .75rem 0 0">Logout</BodyTextAlt>
            <WhiteButton className="no-stroke" w="40px" h="40px" pd="0">
              <LogOutIcon />
            </WhiteButton>
          </FlexBox>
        </FlexBox>
      </header>
      <div
        style={{
          width: '90%',
          padding: '28px 0 0px',
          margin: '0 auto',
          maxWidth: '1286px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <WhiteButton onClick={() => setIsModalOpen(true)}>Add Address</WhiteButton>
        <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen} modalMaxWidth="650px">
          <div
            style={{
              padding: '0 30px',
              margin: '12px auto 30px',
              maxWidth: '610px',
            }}
          >
            <CreateAddressForm
              cookie={Cookies.get('KEPT_SESS')}
              setEditingAddress={setEditingAddress}
              setIsModalOpen={setIsModalOpen}
              setRefetch={setRefetch}
            />
          </div>
        </Modal>
      </div>
      <Grid className="addresses-main">
        {customer && customer.addresses && customer.addresses.edges.length ? (
          customer.addresses.edges.map((address, i) => (
            <div className="address" key={i}>
              {editingAddress === i ? (
                <EditAddress
                  googleApiLoaded={googleApiLoaded}
                  address={address.node}
                  setEditingAddress={setEditingAddress}
                  setRefetch={setRefetch}
                  setIsAddressDisclaimerModalOpen={setIsAddressDisclaimerModalOpen}
                  setHandleAgreeAddressDisclaimer={setHandleAgreeAddressDisclaimer}
                />
              ) : (
                <Address
                  address={address}
                  i={i}
                  customer={customer}
                  setEditingAddress={setEditingAddress}
                  setRefetch={setRefetch}
                  setIsAddressDisclaimerModalOpen={setIsAddressDisclaimerModalOpen}
                  setHandleAgreeAddressDisclaimer={setHandleAgreeAddressDisclaimer}
                />
              )}
            </div>
          ))
        ) : (
          <BodyText>No Addresses Found.</BodyText>
        )}
      </Grid>
      <AddressDisclaimerModal
        open={isAddressDisclaimerModalOpen}
        onClose={handleCloseAddressDisclaimerModal}
        onAgree={handleAgreeAddressDisclaimer}
      />
    </PageContainerEcomm>
  );
};

export default AddressesPage;
