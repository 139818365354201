import { AddressForm } from 'components/AccountPages';
import { saveAddress } from 'helpers/requests/customer-requests';
import React from 'react';
import Cookies from 'js-cookie';

const EditAddress = ({
  googleApiLoaded,
  address,
  setEditingAddress,
  setRefetch,
  setIsAddressDisclaimerModalOpen,
  setHandleAgreeAddressDisclaimer,
}) => {
  const handleSaveAddress = (values, addressID, setSubmitting) => {
    setSubmitting(false);
    setIsAddressDisclaimerModalOpen(true);

    const saveAddressFn = async () => {
      const { firstName, lastName, address1, address2, city, provinceCode, zip, countryCodeV2 } =
        values;
      const mailingAddressInput = {
        firstName,
        lastName,
        address1,
        address2,
        city,
        province: provinceCode,
        zip,
        country: countryCodeV2,
      };

      await saveAddress(Cookies.get('KEPT_SESS'), mailingAddressInput, addressID);
      setRefetch(r => !r);
      setEditingAddress(false);
    };

    setHandleAgreeAddressDisclaimer(() => saveAddressFn);
  };

  return (
    <AddressForm
      googleApiLoaded={googleApiLoaded}
      address={address}
      setEditingAddress={setEditingAddress}
      saveAddress={handleSaveAddress}
      setIsAddressDisclaimerModalOpen={setIsAddressDisclaimerModalOpen}
      setHandleAgreeAddressDisclaimer={setHandleAgreeAddressDisclaimer}
    />
  );
};

export default EditAddress;
